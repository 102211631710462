import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    fontSize: 14,
    lineHeight: "1.5rem",
    fontWeight: 400,
  },
  palette: {
    background: {
      default: "#fdf7ecd8",
    },
  },
  breakpoints: {
    values: {
      ...createTheme().breakpoints.values,
      "md-lg": 992,
    },
  },
});
