import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

function QRMenu() {
  // Variables
  const isTabletView = useMediaQuery("(min-width:768px)");

  return (
    <Box
      component="section"
      className="md-lg:tw-py-16 max-md-lg:tw-py-8"
      id="qr-menu"
    >
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Grid container spacing={2}>
          <Grid item xs={12} md-lg={7}>
            <Box className="tw-flex tw-flex-col md-lg:tw-items-start max-md-lg:tw-items-center max-md-lg:tw-text-center">
              <Typography
                component="h1"
                className="md-lg:!tw-text-4xl max-md-lg:!tw-text-3xl !tw-font-bold !tw-mb-4"
              >
                <span className="tw-text-blue-650 tw-font-extrabold">
                  QR-Меню
                </span>{" "}
                для вашого закладу: Створіть інноваційне рішення
              </Typography>
              <Typography
                component="h2"
                className="md-lg:!tw-text-3xl max-md-lg:!tw-text-2xl !tw-font-semibold !tw-mb-4"
              >
                Зробіть
                <span className="tw-text-blue-650">
                  {" "}
                  меню вашого закладу
                </span>{" "}
                цифровим і зручним
              </Typography>
              <Link
                href="https://appetiteservices.com/menu/ZdGRVNKQXke47K2hXFd3b8AhaGE2?establishmentId=-O6ui9PC5MiZ9d1ZeoIY"
                target="_blank"
                underline="none"
                className="tw-inline-flex tw-bg-blue-650 !tw-text-white tw-py-2.5 tw-px-8 !tw-font-semibold tw-no-underline tw-rounded-3xl"
              >
                Приклад меню
              </Link>
              {isTabletView && (
                <>
                  <Box
                    component="img"
                    className="tw-my-4"
                    src="/img/home-view-images/qrcode.svg"
                    alt="qr-code"
                  />
                  <Box component="p" className="tw-m-0">
                    Відскануй Qr код, щоб переглянути меню на телефоні
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md-lg={5} className="tw-w-full">
            <Box className="tw-flex tw-flex-col tw-items-center">
              <Box
                component="img"
                className="tw-max-w-64 tw-w-full"
                src="/img/home-view-images/iPhone-dark-theme.png"
                alt="iPhone"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default QRMenu;
