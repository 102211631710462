import { Box, Container, Grid, Typography } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import React from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function FeatureCard({ title, description }) {
  return (
    <Box className="tw-flex tw-items-start tw-gap-x-2 tw-h-full">
      <CheckCircleIcon className="tw-mt-1" />
      <Box>
        <Typography
          className="!tw-text-2xl !tw-font-semibold !tw-mb-1"
          component="h3"
        >
          {title}
        </Typography>
        {description && <Typography component="p">{description}</Typography>}
      </Box>
    </Box>
  );
}

FeatureCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

function ServiceFeatures() {
  return (
    <Box
      component="section"
      className="md-lg:tw-my-16 max-md-lg:tw-my-8"
      id="service-features"
    >
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Typography
          className="md-lg:!tw-text-3xl max-md-lg:!tw-text-2xl !tw-font-semibold !tw-mb-8 max-md-lg:tw-w-full tw-text-center"
          component="h2"
        >
          Можливості нашого <span className="tw-text-blue-650">QR </span> меню
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Меню за посиланням"
              description="Легкий доступ до вашого меню через посилання, яке можна розмістити в соціальних мережах або на вашому сайті."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Меню по QR-коду"
              description="Швидкий доступ до вашого меню за допомогою QR-коду, який можна розміщувати на столах, вітринах, дверях та в інших зручних місцях."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Необмежена кількість переглядів"
              description="Ваше електронне меню забезпечує необмежену кількість переглядів, що гарантує безперебійну роботу для всіх клієнтів."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Необмежена кількість розділів та категорій"
              description="Гнучке QR-меню, що підтримує необмежену кількість розділів, категорій та позицій, дозволяючи вам легко організовувати ваші страви."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Віддалене редагування"
              description="Можливість швидко та легко редагувати будь-які елементи вашого меню з віддаленого доступу, що забезпечує актуальність інформації в будь-який час."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Управління кількома ресторанами"
              description="Додавання меню для кількох ресторанів в один особистий кабінет дозволяє вам зручно керувати мережею закладів одночасно, спрощуючи процес управління та оптимізації."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Генератор QR-кодів"
              description="Автоматизований генератор QR-кодів для вашого меню, що дозволяє створювати унікальний QR код для кожного закладу."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Безлімітні сканування"
              description="Ваш QR-код підтримує безлімітну кількість сканувань, що забезпечує легкий доступ до меню для всіх ваших клієнтів без обмежень."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Адаптивний дизайн"
              description="Меню доступне як для мобільних, так і для десктопних пристроїв, забезпечуючи зручність використання на будь-якому екрані."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Перегляд страв"
              description="Користувачі можуть швидко переглядати всі доступні страви та напої з детальними описами та їх зображеннями."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Пошук товарів"
              description="Швидкий та зручний пошук страв у вашому меню, що дозволяє користувачам легко знаходити бажані страви."
            />
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <FeatureCard
              title="Темна та світла тема"
              description="Вибір між темною та світлою темами інтерфейсу, що дозволяє користувачам налаштовувати візуальний вигляд меню відповідно до своїх уподобань та умов освітлення."
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ServiceFeatures;
