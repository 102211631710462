import { DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "../constants";

export const APP_NAME = "Appetite";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "uk",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};
