import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../contexts/Store";
import { Box, Snackbar, Typography } from "@mui/material";
import InfoBox from "./InfoBox";
import classnames from "classnames";
import { BoxWrapper } from "../index";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WifiIcon from "@mui/icons-material/Wifi";
import KeyIcon from "@mui/icons-material/Key";
import PhoneIcon from "@mui/icons-material/Phone";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import XIcon from "@mui/icons-material/X";
import { validLink } from "../../../../helpers";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { IntlMessage } from "../../../../components/util-components";
import { useIntl } from "react-intl";

const iconMap = {
  schedule: <ScheduleIcon />,
  address: <LocationOnIcon />,
  wifi: <WifiIcon />,
  password_wifi: <KeyIcon />,
  first_phone: <PhoneIcon />,
  second_phone: <PhoneIcon />,
  third_phone: <PhoneIcon />,
  fourth_phone: <PhoneIcon />,
  instagram: <InstagramIcon />,
  telegram: <TelegramIcon />,
  facebook: <FacebookIcon />,
  whats_app: <WhatsAppIcon />,
  twitter: <XIcon />,
};

function InfoPanel({ isFooter }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // State
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Variables
  const { formatMessage } = useIntl();
  const { data } = mainStore.menu;
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const establishmentInfo = [
    {
      key: "schedule",
      value: data?.schedule,
      description: formatMessage({
        id: "menu.hours",
      }),
    },
    {
      key: "address",
      value: data?.address,
      description: formatMessage({
        id: "menu.address",
      }),
    },
    { key: "wifi", value: data?.wifi, description: "Wi-Fi:" },
    {
      key: "password_wifi",
      value: data?.password_wifi,
      description: formatMessage({
        id: "menu.passwordWifi",
      }),
    },
  ].filter((item) => Boolean(item.value));
  const contactInfo = [
    {
      key: "first_phone",
      value: data?.first_phone,
      description: formatMessage({
        id: "menu.phoneNumber",
      }),
    },
    {
      key: "second_phone",
      value: data?.second_phone,
      description: formatMessage({
        id: "menu.phoneNumber",
      }),
    },
    {
      key: "third_phone",
      value: data?.third_phone,
      description: formatMessage({
        id: "menu.phoneNumber",
      }),
    },
    {
      key: "fourth_phone",
      value: data?.fourth_phone,
      description: formatMessage({
        id: "menu.phoneNumber",
      }),
    },
  ].filter((item) => Boolean(item.value));
  const socialNetworks = [
    { key: "instagram", value: data?.instagram },
    { key: "telegram", value: data?.telegram },
    { key: "facebook", value: data?.facebook },
    { key: "whats_app", value: data?.whats_app },
    { key: "twitter", value: data?.twitter },
  ].filter((item) => Boolean(item.value));

  // Functions
  function openSocialNetworkLink(typeLink, value) {
    window.open(validLink(typeLink, value), "_blank");
  }

  function handleEstablishmentInfoClick(typeField, value) {
    if (typeField === "address") {
      window.open(validLink("map", value), "_blank");
    }

    if (typeField === "password_wifi") {
      navigator.clipboard.writeText(value);
      setOpenSnackbar(true);
    }
  }

  function handlePhoneNumber(phoneNumber) {
    window.location.href = `tel:${phoneNumber}`;
  }

  function handleCloseSnackbar(_, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  }

  return (
    <>
      {!!establishmentInfo.length && (
        <>
          <Typography
            component="h2"
            className="tw-pb-4 !tw-text-xl !tw-font-bold tw-truncate"
          >
            <IntlMessage id="menu.establishmentInfo" />
          </Typography>
          <Box
            className={classnames("tw-gap-4", {
              "tw-flex tw-flex-col": isMobile || !isFooter,
              "tw-grid tw-grid-cols-[repeat(auto-fill,_minmax(270px,_1fr))]":
                isFooter && isTabletOrLarger,
            })}
          >
            {establishmentInfo.map((item) => {
              const isKeyIncluded = ["address", "password_wifi"].includes(
                item.key,
              );

              return (
                <InfoBox
                  key={item.key}
                  icon={iconMap[item.key]}
                  value={item.value}
                  description={item.description}
                  onClick={() =>
                    handleEstablishmentInfoClick(item.key, item.value)
                  }
                  className={classnames({
                    "tw-cursor-pointer": isKeyIncluded,
                  })}
                  iconClassName={classnames({
                    "!tw-cursor-default": !isKeyIncluded,
                  })}
                  isFooter={isFooter}
                />
              );
            })}
          </Box>
        </>
      )}
      {!!contactInfo.length && (
        <>
          <Typography
            component="h2"
            className="!tw-text-xl !tw-font-bold tw-truncate tw-py-4"
          >
            <IntlMessage id="menu.contactInformation" />
          </Typography>
          <Box
            className={classnames("tw-gap-4", {
              "tw-flex tw-flex-col": isMobile || !isFooter,
              "tw-grid tw-grid-cols-[repeat(auto-fill,_minmax(270px,_1fr))]":
                isFooter && isTabletOrLarger,
            })}
          >
            {contactInfo.map((item) => {
              return (
                <InfoBox
                  key={item.key}
                  icon={iconMap[item.key]}
                  value={item.value}
                  description={item.description}
                  onClick={() => handlePhoneNumber(item.value)}
                  className="tw-cursor-pointer"
                  isFooter={isFooter}
                />
              );
            })}
          </Box>
        </>
      )}
      {!!socialNetworks.length && (
        <>
          <Typography
            component="h2"
            className="!tw-text-xl !tw-font-bold tw-truncate tw-py-4"
          >
            <IntlMessage id="menu.socialNetworks" />
          </Typography>
          <Box className="tw-flex tw-gap-2.5">
            {socialNetworks.map((item) => {
              return (
                <BoxWrapper
                  key={item.key}
                  onClick={() => openSocialNetworkLink(item.key, item.value)}
                >
                  {iconMap[item.key]}
                </BoxWrapper>
              );
            })}
          </Box>
        </>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1500}
        onClose={handleCloseSnackbar}
        message={formatMessage({
          id: "menu.passwordCopied",
        })}
      />
    </>
  );
}

InfoPanel.propTypes = {
  isFooter: PropTypes.bool,
};

export default observer(InfoPanel);
