import { action, makeObservable, observable } from "mobx";
import { onValue, ref } from "firebase/database";
import { database } from "../../configs";
import { getUserId } from "../../helpers";

export default class Subscription {
  mainStore;

  data = null;

  constructor(mainStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
    });

    this.mainStore = mainStore;
  }

  async index() {
    const userID = await getUserId();

    const refValue = ref(database, `${userID}/Subscription`);

    await onValue(refValue, (data) => {
      this.setData(data.val());
    });
  }

  setData(value) {
    this.data = value || {};
  }
}
