import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { Auth } from "./auth";
import { AUTH_PREFIX_PATH } from "./auth/constants";
import { AdminPanel } from "./admin-panel";
import { ADMIN_PANEL_PREFIX_PATH } from "../constants";
import { useBodyClass } from "../hooks";
import { Menu } from "./menu";
import { MENU_PREFIX_PATH } from "./menu/constants";
import { THEME_CONFIG } from "../configs";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { HomeView, OfferAgreement } from "./home-view";
import { OFFER_AGREEMENT_PATH } from "./home-view/constants";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
const REQUIRED_TRANSLATION_IDS = [
  "-OA8C82YRPBAOnXgqHJP",
  "-OAndUepLDL3HKIJHbZ7",
  "-OAyKKUZTSPbipjqI0gI",
  "-OBMsiCXpRkxetEItZkA",
];

export const FeaturesNavigation = (props) => {
  // Props
  const { locale, location, direction } = props;

  // Variables
  const params = new URLSearchParams(location.search);
  const establishmentId = params.get("establishmentId");
  const hasTranslationNeeded =
    REQUIRED_TRANSLATION_IDS.includes(establishmentId);
  const currentAppLocale = hasTranslationNeeded
    ? AppLocale["en"]
    : AppLocale[locale];

  // Hooks
  useBodyClass(`dir-${direction}`);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <HomeView />
          </Route>
          <Route exact path={OFFER_AGREEMENT_PATH}>
            <OfferAgreement />
          </Route>
          <Route path={MENU_PREFIX_PATH}>
            <Menu />
          </Route>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Route path={AUTH_PREFIX_PATH}>
              <Auth />
            </Route>
            <Route path={ADMIN_PANEL_PREFIX_PATH}>
              <AdminPanel direction={direction} location={location} />
            </Route>
          </ThemeSwitcherProvider>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale, direction } = theme;
  return { locale, direction };
};

export default withRouter(connect(mapStateToProps)(FeaturesNavigation));
