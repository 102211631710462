import React, { useContext } from "react";

import { Box, Container, Modal, SwipeableDrawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuProduct from "./MenuProduct";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import { StoreContext } from "../../../../../../contexts/Store";
import ProductContent from "./ProductContent";
import { CONTAINER_MAX_WIDTH } from "../../../../constants";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const closeIconStyles =
  "tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-absolute tw-z-20 tw-top-3 tw-right-3 tw-w-10 tw-h-10 tw-rounded-full";
const modalStyles =
  "tw-flex tw-flex-col tw-rounded-lg tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-translate-y-[-50%] tw-w-11/12 tw-max-w-md tw-max-h-[80vh] tw-outline-none";

function ProductPreview({
  isLast,
  selectedProduct,
  open,
  handleClose,
  openDrawer,
  toggleDrawer,
  ...props
}) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const { isThemeDark, mainColor } = mainStore.menu;

  return (
    <Container maxWidth={CONTAINER_MAX_WIDTH}>
      {isTabletOrLarger && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
            className={modalStyles}
          >
            <Box
              className={closeIconStyles}
              onClick={handleClose}
              style={{ backgroundColor: mainColor }}
            >
              <CloseIcon className="tw-text-white" />
            </Box>
            <Box className="tw-flex-auto tw-overflow-y-auto tw-rounded-lg">
              <MenuProduct
                isPreview
                className="tw-flex-col-reverse tw-pt-0"
                {...props}
              />
            </Box>
          </Box>
        </Modal>
      )}
      {isMobile && (
        <SwipeableDrawer
          anchor="bottom"
          open={openDrawer}
          onOpen={() => toggleDrawer(true)}
          onClose={() => toggleDrawer(false)}
          swipeAreaWidth={0}
          sx={{
            "& .MuiDrawer-paper": {
              maxWidth: "448px",
              maxHeight: "80vh",
              margin: "auto",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
            },
          }}
        >
          <Box
            className={classnames(
              "tw-overflow-y-auto tw-flex tw-flex-col tw-rounded-t-2xl",
              {
                "tw-bg-neutral-925": isThemeDark,
              },
            )}
          >
            <Box
              className={closeIconStyles}
              onClick={() => toggleDrawer(false)}
              style={{ backgroundColor: mainColor }}
            >
              <CloseIcon className="tw-text-white" />
            </Box>
            <Box className="tw-flex-auto">
              <ProductContent
                isLast={isLast}
                selectedProduct={selectedProduct}
                className="tw-flex-col-reverse tw-pt-0"
                isPreview
                {...props}
              />
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
    </Container>
  );
}

ProductPreview.propTypes = {
  isLast: PropTypes.bool,
  selectedProduct: PropTypes.object,
  isPreview: PropTypes.bool,
  className: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  openDrawer: PropTypes.bool,
};

export default observer(ProductPreview);
