import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../../contexts/Store";
import { Box, Container, Pagination, Typography } from "@mui/material";
import { MenuProduct } from "../menu-products/menu-product";
import { CONTAINER_MAX_WIDTH } from "../../../constants";
import MenuFooter from "../../menu-footer/MenuFooter";
import classnames from "classnames";
import { useMediaQuery } from "react-responsive";
import { MenuDivider } from "../../../shared-components";
import { IntlMessage } from "../../../../../components/util-components";

function MenuSearchContent() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // State
  const [currentPage, setCurrentPage] = useState(1);

  // Variables
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { data, search, isThemeDark, mainColor } = mainStore.menu;

  const sectionIDs = Object.values(data?.Sections ?? {})
    .filter((section) => !section.section_active)
    .map((section) => section.id);
  const categoryIDs = Object.values(data?.Categories ?? {})
    .filter((category) => !category.category_active)
    .map((category) => category.id);
  const products = Object.values(data?.Products ?? {}).filter(
    (product) =>
      product.product_active &&
      !sectionIDs.includes(product.section_id) &&
      !categoryIDs.includes(product.category_id),
  );

  const filteredProductList = filterAndSortProducts(products, search);

  const ITEMS_PER_PAGE = 10;
  const paginatedProductList = filteredProductList.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  );

  // Functions
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }, 0);
  };

  function filterAndSortProducts(products, searchQuery) {
    const lowerCaseQuery = searchQuery.toLowerCase();

    const filteredProducts = products.filter((product) => {
      return (
        product.name?.toLowerCase().includes(lowerCaseQuery) ||
        product.description?.toLowerCase().includes(lowerCaseQuery) ||
        product.price?.toString().includes(lowerCaseQuery)
      );
    });

    const sortedProducts = filteredProducts.sort((a, b) => {
      const aNameMatches = a.name.toLowerCase().startsWith(lowerCaseQuery);
      const bNameMatches = b.name.toLowerCase().startsWith(lowerCaseQuery);

      if (aNameMatches && !bNameMatches) return -1;
      if (!aNameMatches && bNameMatches) return 1;

      return a.name.localeCompare(b.name);
    });

    return sortedProducts;
  }

  return (
    <>
      {!!paginatedProductList.length ? (
        <Box className="tw-mt-5">
          {isMobile && <MenuDivider />}
          <Box
            className={classnames({
              "tw-bg-neutral-50": !isThemeDark && isMobile,
              "tw-bg-neutral-950": isThemeDark && isMobile,
            })}
          >
            <Container maxWidth={CONTAINER_MAX_WIDTH}>
              <Typography
                component="h2"
                className={classnames(
                  "!tw-text-2xl",
                  "!tw-font-bold",
                  "tw-truncate",
                  "tw-py-5",
                  "tw-uppercase",
                  {
                    "tw-bg-neutral-50": !isThemeDark && isTabletOrLarger,
                    "tw-bg-neutral-950": isThemeDark && isTabletOrLarger,
                    "tw-border-solid": isTabletOrLarger,
                    "tw-rounded-t-md": isTabletOrLarger,
                    "tw-border-neutral-300": !isThemeDark && isTabletOrLarger,
                    "tw-border-neutral-600": isThemeDark && isTabletOrLarger,
                    "tw-border": isTabletOrLarger,
                    "tw-px-4": isTabletOrLarger,
                  },
                )}
              >
                <IntlMessage id="menu.yourFoundProducts" />
              </Typography>
            </Container>
          </Box>
          {isMobile && <MenuDivider />}
          <Box
            className={classnames({
              "tw-mb-5": isTabletOrLarger,
            })}
          >
            {paginatedProductList.map((product, index) => (
              <MenuProduct
                key={product.id}
                {...product}
                isLast={index === paginatedProductList.length - 1}
              />
            ))}
          </Box>
          {filteredProductList.length > ITEMS_PER_PAGE && (
            <Container maxWidth={CONTAINER_MAX_WIDTH}>
              <Pagination
                count={Math.ceil(filteredProductList.length / ITEMS_PER_PAGE)}
                page={currentPage}
                onChange={handleChangePage}
                color="secondary"
                variant="outlined"
                shape="rounded"
                className="tw-flex tw-justify-end tw-mb-5 tw-border-neutral-400"
                size="large"
                siblingCount={0}
                sx={{
                  "& .Mui-selected": {
                    backgroundColor: "transparent !important",
                    borderColor: `${mainColor} !important`,
                    color: `${mainColor} !important`,
                  },
                  "& .MuiPaginationItem-root": {
                    borderColor: "rgb(163 163 163 / 1)",
                    fontWeight: "700",

                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                  "& .MuiPagination-ul li:last-of-type .MuiPaginationItem-root":
                    {
                      marginRight: 0,
                    },
                }}
              />
            </Container>
          )}
        </Box>
      ) : (
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          <Box component="h2" className="tw-text-center">
            <IntlMessage
              id="menu.noProductsFound"
              values={{
                query: (
                  <Box component="i" className="tw-opacity-70 tw-break-words">
                    {search}
                  </Box>
                ),
              }}
            />
          </Box>
        </Container>
      )}
      <MenuFooter />
    </>
  );
}

export default observer(MenuSearchContent);
