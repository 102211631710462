import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import React from "react";
import { useLocation } from "react-router-dom";
import { OFFER_AGREEMENT_PATH } from "../constants";
import { APP_NAME } from "../../../configs";

function Footer() {
  // Hooks
  const location = useLocation();

  return (
    <Box
      component="footer"
      className="tw-py-8"
      sx={{ backgroundColor: "#181a22" }}
    >
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Grid container spacing={2}>
          <Grid item xs={12} md-lg={6}>
            <Link href="/" underline="none" className="tw-flex">
              <Box component="img" src="/img/logo-white.svg" alt="logo" />
            </Link>
          </Grid>
          <Grid item xs={12} md-lg={6}>
            <Box className="tw-flex tw-justify-end max-md-lg:tw-justify-start tw-gap-x-2">
              {location.pathname !== OFFER_AGREEMENT_PATH && (
                <Link
                  href={OFFER_AGREEMENT_PATH}
                  underline="none"
                  className="!tw-text-white"
                >
                  Договір оферти
                </Link>
              )}
              <Typography component="p" className="tw-text-white">
                &copy; {new Date().getFullYear()} {APP_NAME}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
