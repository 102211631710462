import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../menu/constants";
import { Link as RouterLink } from "react-router-dom";
import { SIGN_UP_PATH } from "../../auth/constants";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PropTypes from "prop-types";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../configs";

function PlanInfo({ title, isColor, hasDisabledIcon }) {
  return (
    <Box
      className={classnames("tw-flex tw-items-center tw-gap-x-1", {
        "tw-text-white": isColor,
      })}
    >
      {!hasDisabledIcon && (
        <CheckCircleOutlineIcon
          className={classnames({
            "tw-text-blue-650": !isColor,
          })}
        />
      )}
      {title}
    </Box>
  );
}

PlanInfo.propTypes = {
  title: PropTypes.string.isRequired,
  isColor: PropTypes.bool,
  hasDisabledIcon: PropTypes.bool,
};

const USD = "USD";
const UAH = "UAH";

function Price() {
  // States
  const [currency, setCurrency] = useState(USD);
  const [exchangeRate, setExchangeRate] = useState([]);

  // Variables
  const usdData = exchangeRate.find((currency) => currency.ccy === "USD");

  // Effects
  useEffect(() => {
    async function handleRequest() {
      const getPrivatBankExchangeRates = httpsCallable(
        functions,
        "getPrivatBankExchangeRates",
      );
      try {
        const result = await getPrivatBankExchangeRates();
        setExchangeRate(result.data.exchangeRates);
      } catch (error) {
        window.console.error("Failed to get exchange rates:", error);
      }
    }

    handleRequest();
  }, []);

  // Functions
  function handleChange(selectedCurrency) {
    setCurrency(selectedCurrency);
  }

  return (
    <Box component="section" id="price">
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Box
          sx={{ backgroundColor: "#181a22" }}
          className="tw-relative tw-overflow-hidden tw-rounded-[30px] tw-px-6 md-lg:tw-py-16 max-md-lg:tw-py-8"
        >
          <Typography
            className="tw-relative tw-z-10 tw-text-white tw-text-center md-lg:!tw-text-3xl max-md-lg:!tw-text-2xl !tw-font-semibold !tw-mb-8 max-md-lg:tw-w-full max-md-lg:tw-text-center"
            component="h2"
          >
            Оберіть <span className="tw-text-blue-650">свій </span> тарифний
            план
          </Typography>
          <Box className="tw-absolute tw--top-2/4 tw-left-2/4 tw--translate-x-2/4">
            <Box
              component="img"
              src="/img/home-view-images/blue-dotes.png"
              alt="blue-dotes"
            />
          </Box>
          <Box className="tw-relative tw-z-10 tw-flex tw-justify-center tw-items-center">
            <Box className="tw-max-w-96 tw-w-full tw-inline-flex tw-p-1.5 tw-bg-white tw-rounded-xl tw-font-bold tw-mb-6 max-xs:tw-flex-col max-xs:tw-text-center">
              <Box
                className={classnames(
                  "tw-px-8 tw-py-2.5 tw-cursor-pointer tw-rounded-xl",
                  {
                    "tw-bg-blue-650 tw-text-white": currency === USD,
                  },
                )}
                onClick={() => handleChange(USD)}
              >
                Ціни в доларах
              </Box>
              <Box
                className={classnames(
                  "tw-px-8 tw-py-2.5 tw-cursor-pointer tw-rounded-xl",
                  {
                    "tw-bg-blue-650 tw-text-white": currency === UAH,
                  },
                )}
                onClick={() => handleChange(UAH)}
              >
                Ціни в гривнях
              </Box>
            </Box>
          </Box>
          <Grid container spacing={2} className="tw-relative tw-z-10">
            <Grid item xs={12} md-lg={4}>
              <Box className="tw-max-w-96 tw-m-auto tw-bg-white tw-p-6 md:tw-p-10 tw-rounded-3xl tw-h-full">
                <Typography
                  className="!tw-text-2xl !tw-font-semibold"
                  component="h3"
                >
                  Стандартний
                </Typography>
                <Box className="tw-text-blue-650 tw-text-4xl tw-font-bold tw-pt-5 tw-pb-4 md:tw-pt-8 md:tw-pb-6">
                  {currency === USD ? (
                    <>
                      $12<span className="tw-text-base">/місяць</span>
                    </>
                  ) : (
                    <>
                      ₴{Math.round(usdData?.sale * 12)}
                      <span className="tw-text-base">/місяць</span>
                    </>
                  )}
                </Box>
                <Divider />
                <Box className="tw-flex tw-flex-col tw-my-6 tw-gap-y-2">
                  <PlanInfo
                    title="Ідеально для маленького бізнесу"
                    hasDisabledIcon
                  />
                  <PlanInfo title="Весь функціонал" />
                  <PlanInfo title="Один заклад" />
                </Box>
                <RouterLink
                  className="tw-inline-flex tw-bg-blue-650 !tw-text-white tw-py-2.5 tw-px-8 tw-font-semibold tw-no-underline tw-rounded-3xl max-md-lg:tw-mx-auto"
                  to={`${SIGN_UP_PATH}`}
                >
                  Вибрати план
                </RouterLink>
              </Box>
            </Grid>
            <Grid item xs={12} md-lg={4}>
              <Box className="tw-max-w-96 tw-m-auto tw-bg-blue-650 tw-p-6 md:tw-p-10 tw-rounded-3xl tw-h-full">
                <Typography
                  className="tw-text-white !tw-text-2xl !tw-font-semibold"
                  component="h3"
                >
                  Основний
                </Typography>
                <Box className="tw-text-white tw-text-4xl tw-font-bold tw-pt-5 tw-pb-4 md:tw-pt-8 md:tw-pb-6">
                  {currency === USD ? (
                    <>
                      $18<span className="tw-text-base">/місяць</span>
                    </>
                  ) : (
                    <>
                      ₴{Math.round(usdData?.sale * 18)}
                      <span className="tw-text-base">/місяць</span>
                    </>
                  )}
                </Box>
                <Divider className="tw-bg-white" />
                <Box className="tw-flex tw-flex-col tw-my-6 tw-gap-y-2">
                  <PlanInfo
                    title="Для середнього і більшого бізнесу"
                    hasDisabledIcon
                    isColor
                  />
                  <PlanInfo title="Весь функціонал" isColor />
                  <PlanInfo title="До трьох закладів" isColor />
                </Box>
                <RouterLink
                  className="tw-inline-flex tw-bg-white tw-text-inherit tw-py-2.5 tw-px-8 tw-font-semibold tw-no-underline tw-rounded-3xl max-md-lg:tw-mx-auto"
                  to={`${SIGN_UP_PATH}`}
                >
                  Вибрати план
                </RouterLink>
              </Box>
            </Grid>
            <Grid item xs={12} md-lg={4}>
              <Box className="tw-max-w-96 tw-m-auto tw-bg-white tw-p-6 md:tw-p-10 tw-rounded-3xl tw-h-full">
                <Typography
                  className="!tw-text-2xl !tw-font-semibold"
                  component="h3"
                >
                  Плюс
                </Typography>
                <Box className="tw-text-blue-650 tw-text-4xl tw-font-bold tw-pt-5 tw-pb-4 md:tw-pt-8 md:tw-pb-6">
                  {currency === USD ? (
                    <>
                      $24<span className="tw-text-base">/місяць</span>
                    </>
                  ) : (
                    <>
                      ₴{Math.round(usdData?.sale * 24)}
                      <span className="tw-text-base">/місяць</span>
                    </>
                  )}
                </Box>
                <Divider />
                <Box className="tw-flex tw-flex-col tw-my-6 tw-gap-y-2">
                  <PlanInfo
                    title="Для великого бізнесу, мережі"
                    hasDisabledIcon
                  />
                  <PlanInfo title="Весь функціонал" />
                  <PlanInfo title="До пʼяти закладів" />
                </Box>
                <RouterLink
                  className="tw-inline-flex tw-bg-blue-650 !tw-text-white tw-py-2.5 tw-px-8 tw-font-semibold tw-no-underline tw-rounded-3xl max-md-lg:tw-mx-auto"
                  to={`${SIGN_UP_PATH}`}
                >
                  Вибрати план
                </RouterLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Price;
